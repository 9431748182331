<template>
  <div id="body-box">
    <p class="preload-font">一期一会</p>
  </div>
</template>

<script>
import ChatClientDirect from "@/api/chat/MemberClientDirect";
import * as constants from "@/components/ChatRenderer/constants";
import ChatClientTest from "@/api/chat/ChatClientTest";
// import {set} from "lodash";


export default {
  name: "Cele",
  props: {
    roomId: {
      type: Number,
      default: null
    },
    strConfig: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chatClient: null,
      waitList: [],
      minDanmakuInterval: 100,
      maxDanmakuInterval: 1000,
      showCele: false,
      queue: [],
      busy: false,
      queueIndex: 0
    }
  },
  mounted() {
    this.initChatClient()
    this.queueLoop()
  },
  beforeDestroy() {
    if (this.chatClient) {
      this.chatClient.stop()
    }
  },
  methods: {
    initChatClient() {
      if (this.roomId === null) {
        this.chatClient = new ChatClientTest(this.minDanmakuInterval, this.maxDanmakuInterval)
      } else {
        this.chatClient = new ChatClientDirect(this.roomId)
      }
      this.chatClient.onAddText = this.onAddText
      this.chatClient.onAddGift = this.onAddGift
      this.chatClient.onAddMember = this.onAddMember
      this.chatClient.onAddSuperChat = this.onAddSuperChat
      // this.chatClient.onDelSuperChat = this.onDelSuperChat
      // this.chatClient.onUpdateTranslation = this.onUpdateTranslation
      this.chatClient.start()
    },
    onAddText() {

    },
    onAddGift() {

    },
    onAddSuperChat() {

    },
    onAddMember(data) {
      let price = data.privilegeType == 3 ? 198 : data.privilegeType == 2 ? 1998 : 19998
      let message = {
        id: data.id,
        avatarUrl: data.avatarUrl,
        time: new Date(data.timestamp * 1000),
        authorName: data.authorName,
        privilegeType: data.privilegeType,
        title: data.authorName,
        price: price
      }
      var tmp = [message.avatarUrl, message.authorName, message.privilegeType]
      console.log('新增舰长：' + message.authorName + '，舰长类型：'+message.privilegeType)
      this.queue.push(tmp)
    },
    start() {
      this.chatClient.start()
    },
    stop() {
      this.chatClient.stop()
    },
    //添加元素并调起倒计时
    addRow(avatar, id, captain_type) {
      this.busy = true;
      var background_url = ''
      var styleSheet = ''
      var uuid = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      //识别舰长类型调参
      if (captain_type == 3) {
        background_url = 'https://i1.xuehusang.cn/static/fullScreenCele/%E8%83%8C%E6%99%AF.webp?' + Math.floor(Math.random() * 10000)
        styleSheet = 'color: #6598D1;'
      } else {
        background_url = 'https://i1.xuehusang.cn/static/fullScreenCele/%E6%80%BB%E7%9D%A3%E8%83%8C%E6%99%AF.webp?' + Math.floor(Math.random() * 10000)
        styleSheet = 'color: #830E2F;'
      }
      var newElement = document.createElement('div');
      newElement.style = 'height:1080px;'
      newElement.id = uuid
      var avatar_frame_url = 'https://i1.xuehusang.cn/static/fullScreenCele/%E5%A4%B4%E5%83%8F.webp?' + Math.floor(Math.random() * 10000) + '")'
      newElement.innerHTML = '<img src="' + background_url + '" class="full-screen-cele"><img src="' + avatar_frame_url + '" class="avatar-frame" id="avatar-frame"/><img class="avatar" src="' + avatar + '"><div class="welcome" style="' + styleSheet + '">欢迎 ' + id + ' 加入大航海</div></img></img>';
      // var parentElement = document.getElementById('body-box');
      setTimeout(() => {
        var parentElement = document.getElementById("body-box");
        parentElement.appendChild(newElement);
        this.deleteRow(uuid);
      }, 5000)
      // parentElement.appendChild(newElement);
    },
    deleteRow(uuid) {
      const that = this
      setTimeout(function () {
        that.busy = false
        // console.log('提前放空队列状态')
      }, 500)
      setTimeout(function () {
        var parentBox = document.getElementById('body-box')
        var deleteRow = document.getElementById(uuid)
        parentBox.removeChild(deleteRow)
      }, 6000)
    },
    //  最重要的队列部分
    queueDetect() {
      if (this.busy == false) {
        //如果队列空闲
        if (this.queue.length > this.queueIndex) {
          this.addRow(this.queue[this.queueIndex][0], this.queue[this.queueIndex][1], this.queue[this.queueIndex][2])
          this.queueIndex = this.queueIndex + 1;
          // console.log('增加任务')
        } else {
          // console.log('队列空置')
        }
      } else {
        // console.log('队列繁忙')
      }
    },
    queueLoop() {
      setInterval(this.queueDetect, 500)
    }
  },
  components: {}
}
</script>

<style>
@font-face {
  font-family: 'SmileySans-Oblique';
  font-style: normal;
  src: url(https://rec.xuehusang.cn/font/SmileySans-Oblique-2.ttf) format('truetype');
}

#body-box {
  width: 1920px;
  height: 1080px;
  overflow: hidden;
}

.preload-font {
  font-family: SmileySans-Oblique;
  color: transparent !important;
  position: absolute;
  top: 0;
  left: 0;
}

.full-screen-cele {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 1080px;
}

body {
  background-color: transparent !important;
}

.welcome {
  opacity: 0;
  font-family: SmileySans-Oblique;
  font-size: 80px;
  display: table;
  margin: 840px auto;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  padding: 10px 150px;
  animation: fadeInAndOut 4s;
  animation-delay: 0.7s;
}

.avatar {
  opacity: 0;
  width: 260px;
  height: 260px;
  border-radius: 50%;
  position: absolute;
  top: 335px;
  left: 845px;
  animation: fadeInAndOut 4s;
  animation-delay: 0.5s;
}

.avatar-frame {
  opacity: 0;
  width: 478px;
  height: 423px;
  border: 0px transparent solid;
  /*background-image: url("https://i1.xuehusang.cn/static/fullScreenCele/%E5%A4%B4%E5%83%8F.webp");*/
  position: absolute;
  left: 730px;
  top: 255px;
  background-size: 100% 100%;
  z-index: 10;
  animation: fadeInAndOut 4s;
  animation-delay: 0.5s;
}

@keyframes fadeInAndOut {
  0% {
    opacity: 0;
    scale: 0.8;
  }
  23% {
    opacity: 1;
    scale: 1;
  }
  75% {
    opacity: 1;
    transform: translatex(0);
  }
  100% {
    opacity: 0;
  }
}
</style>