<template>
  <iframe src="https://rec.xuehusang.cn/sc_danmu.html" width="100%" height="98%" name="sc记录" frameborder="0"></iframe>
</template>

<script>
export default {
  name: "Superchat"
}
</script>

<style scoped>

</style>