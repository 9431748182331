<template>
  <div class="main">
    <iframe src="http://test.xuehusang.cn/room/test?minGiftPrice=0.1&minTickerPrice=0.1&showDanmaku=true&showTranslateDanmakuOnly=false&translationSign=%E3%80%90&showSuperchat=false&showNewMember=false&showGift=false&showGiftInfo=false&mergeSimilarDanmaku=false&mergeGift=true&danmakuAtBottom=false&tickerAtButtom=false&maxNumber=50&fadeOutNum=3&pinTime=0&imageShowType=2&maxImage=1&blockGiftDanmaku=true&blockLevel=0&blockNewbie=false&blockNotMobileVerified=false&blockKeywords=&blockUsers=&blockMedalLevel=0&minDanmakuInterval=100&maxDanmakuInterval=300&relayMessagesByServer=false&autoTranslate=false&giftUsernamePronunciation=" width="33%" frameborder="0" name="iframe" id="iframe1" class="main"></iframe>
    <iframe src="http://test.xuehusang.cn/room/test?minGiftPrice=0.1&minTickerPrice=0.1&showDanmaku=false&showTranslateDanmakuOnly=false&translationSign=%E3%80%90&showSuperchat=true&showNewMember=false&showGift=false&showGiftInfo=false&mergeSimilarDanmaku=false&mergeGift=true&danmakuAtBottom=false&tickerAtButtom=false&maxNumber=50&fadeOutNum=3&pinTime=0&imageShowType=2&maxImage=1&blockGiftDanmaku=true&blockLevel=0&blockNewbie=false&blockNotMobileVerified=false&blockKeywords=&blockUsers=&blockMedalLevel=0&minDanmakuInterval=100&maxDanmakuInterval=300&relayMessagesByServer=false&autoTranslate=false&giftUsernamePronunciation=" width="33%" frameborder="0" name="sc" id="iframe2" class="main"></iframe>
    <iframe src="http://test.xuehusang.cn/room/test?minGiftPrice=0.1&minTickerPrice=0.1&showDanmaku=false&showTranslateDanmakuOnly=false&translationSign=%E3%80%90&showSuperchat=false&showNewMember=true&showGift=true&showGiftInfo=true&mergeSimilarDanmaku=false&mergeGift=true&danmakuAtBottom=false&tickerAtButtom=false&maxNumber=50&fadeOutNum=3&pinTime=0&imageShowType=2&maxImage=1&blockGiftDanmaku=true&blockLevel=0&blockNewbie=false&blockNotMobileVerified=false&blockKeywords=&blockUsers=&blockMedalLevel=0&minDanmakuInterval=100&maxDanmakuInterval=300&relayMessagesByServer=false&autoTranslate=false&giftUsernamePronunciation=" width="33%" frameborder="0" name="gift" id="iframe3" class="main"></iframe>
  </div>
</template>

<script>
export default {
  name: "Screen"
}

</script>

<style scoped>

/* Transparent background */
yt-live-chat-renderer {
  background-color: transparent !important;
}

yt-live-chat-ticker-renderer {
  background-color: transparent !important;
  box-shadow: none !important;
}

yt-live-chat-author-chip.yt-live-chat-text-message-renderer {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

yt-live-chat-author-chip #author-name {
  background-color: seashell;
  font-weight: bold;
  background-color: transparent !important;
  /* text-shadow: -1.5px -1.5px #FFFFFF, -1.5px -0.5px #FFFFFF, -1.5px 0.5px #FFFFFF, -1.5px 1.5px #FFFFFF, -0.5px -1.5px #FFFFFF, -0.5px -0.5px #FFFFFF, -0.5px 0.5px #FFFFFF, -0.5px 1.5px #FFFFFF, 0.5px -1.5px #FFFFFF, 0.5px -0.5px #FFFFFF, 0.5px 0.5px #FFFFFF, 0.5px 1.5px #FFFFFF, 1.5px -1.5px #FFFFFF, 1.5px -0.5px #FFFFFF, 1.5px 0.5px #FFFFFF, 1.5px 1.5px #FFFFFF; */
  margin-left: 10px;
}

/* Hide scrollbar */
yt-live-chat-item-list-renderer #items {
  overflow: hidden !important;
}

yt-live-chat-item-list-renderer #item-scroller {
  overflow: hidden !important;
}

yt-live-chat-text-message-renderer #content,
yt-live-chat-membership-item-renderer #content {
  overflow: visible !important;
}

/* Hide header and input */
yt-live-chat-header-renderer,
yt-live-chat-message-input-renderer {
  display: none !important;
}

/* Hide unimportant messages */
yt-live-chat-text-message-renderer[is-deleted],
yt-live-chat-membership-item-renderer[is-deleted] {
  display: none !important;
}

yt-live-chat-mode-change-message-renderer,
yt-live-chat-viewer-engagement-message-renderer,
yt-live-chat-restricted-participation-renderer {
  display: none !important;
}

yt-live-chat-text-message-renderer a,
yt-live-chat-membership-item-renderer a {
  text-decoration: none !important;
}

/* Reduce side padding */
yt-live-chat-text-message-renderer {
  padding-left: 4px !important;
  padding-right: 4px !important;

}

/* Outlines */
yt-live-chat-renderer * {
  font-family: "Imprima", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 18px !important;
  line-height: 18px !important;
}

/* Avatars */
yt-live-chat-text-message-renderer #author-photo img,
yt-live-chat-paid-message-renderer #author-photo img,
yt-live-chat-membership-item-renderer #author-photo img {

  width: 36px !important;
  height: 36px !important;
  border-radius: 36px !important;
  margin-right: 3px !important;
}

yt-live-chat-paid-message-renderer #author-photo{

  width: 36px !important;
  height: 36px !important;
  border-radius: 36px !important;
  margin-right: 3px !important;
  margin: 0px;
  padding: 0px;
  border: 3px solid #ffffff ;
}
yt-live-chat-membership-item-renderer #author-photo{

  width: 36px !important;
  height: 36px !important;
  border-radius: 36px !important;
  margin-right: 3px !important;
  margin: 0px;
  padding: 0px;
  border: 3px solid transparent;
}
yt-live-chat-text-message-renderer[author-type="owner"] #author-photo {
  width: 36px !important;
  height: 36px !important;
  border-radius: 36px !important;
  margin-right: 3px !important;
  margin-top: 15px;
  margin: 0px;
  padding: 0px;
  border: 3px solid #8f4ff5;
}
yt-live-chat-text-message-renderer[author-type="moderator"] #author-photo {
  width: 36px !important;
  height: 36px !important;
  border-radius: 36px !important;
  margin-right: 3px !important;
  margin: 0px;
  padding: 0px;
  border: 3px solid transparent;
}
yt-live-chat-text-message-renderer[author-type="moderator"] #author-photo::after {
  content: "";
  border: 25px solid transparent;
  margin: 0px;
  padding: 0px;
  border-image: url("https://pb.xuehusang.cn/images/4e63b56b5feeb38807abd76c46bf30e3.png") 149 fill;
  position: absolute;
  top: 12px;
  left:-2px;
}
yt-live-chat-text-message-renderer[privilegetype="1"][author-type="member"] #author-photo {
  width: 36px !important;
  height: 36px !important;
  border-radius: 36px !important;
  margin-right: 3px !important;
  margin: 0px;
  padding: 0px;
  border: 3px solid #e4c675 ;
}
yt-live-chat-text-message-renderer[privilegetype="1"][author-type="member"] #author-photo::after {
  content: "";
  border: 25px solid transparent;
  margin: 0px;
  padding: 0px;
  border-image: url("https://pb.xuehusang.cn/images/4e63b56b5feeb38807abd76c46bf30e3.png") 149 fill;
  position: absolute;
  top: 12px;
  left:-2px;
}
yt-live-chat-text-message-renderer[privilegetype="2"][author-type="member"] #author-photo {
  width: 36px !important;
  height: 36px !important;
  border-radius: 36px !important;
  margin-right: 3px !important;
  margin: 0px;
  padding: 0px;
  border: 3px solid #814faf !important;
}
yt-live-chat-text-message-renderer[privilegetype="2"][author-type="member"] #author-photo::after {
  content: "";
  border: 25px solid transparent;
  margin: 0px;
  padding: 0px;
  border-image: url("https://pb.xuehusang.cn/images/4e63b56b5feeb38807abd76c46bf30e3.png") 149 fill;
  position: absolute;
  top: 12px;
  left:-2px;
}
yt-live-chat-text-message-renderer[privilegetype="3"][author-type="member"] #author-photo {
  width: 36px !important;
  height: 36px !important;
  border-radius: 36px !important;
  margin-right: 3px !important;
  margin: 0px;
  padding: 0px;
  border: 3px solid transparent;
}
yt-live-chat-text-message-renderer[privilegetype="3"][author-type="member"] #author-photo::after {
  content: "";
  border: 25px solid transparent;
  margin: 0px;
  padding: 0px;
  border-image: url("https://pb.xuehusang.cn/images/4e63b56b5feeb38807abd76c46bf30e3.png") 149 fill;
  position: absolute;
  top: 12px;
  left:-2px;
}
yt-live-chat-text-message-renderer #author-photo{
  width: 36px !important;
  height: 36px !important;
  border-radius: 36px !important;
  margin-top: 15px !important;
  margin-right: 3px !important;
  margin: 0px;
  padding: 0px;
  border: 3px solid #316d8f;
}
yt-live-chat-text-message-renderer #author-photo::after {
  content: "";
  border: 25px solid transparent;
  margin: 0px;
  padding: 0px;
  border-image: url("https://pb.xuehusang.cn/images/b69e881905f087f07a5f86d6806b509d.png") 149 fill;
  position: absolute;
  top: 12px;
  left:-2px;
}
/* Channel names */
yt-live-chat-text-message-renderer #author-name[type="owner"],
yt-live-chat-text-message-renderer yt-live-chat-author-badge-renderer[type="owner"] {
  color: #316d8f !important;
  font-weight:bold;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 20px !important;
  line-height: 24px !important;
}


yt-live-chat-text-message-renderer #author-name,
yt-live-chat-text-message-renderer yt-live-chat-author-badge-renderer {
  font-weight: bold !important;
  color: #316d8f !important;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 20px !important;
  line-height: 24px !important;
}

yt-live-chat-text-message-renderer #author-name[type="moderator"],
yt-live-chat-text-message-renderer yt-live-chat-author-badge-renderer[type="moderator"] {
  color: #626dff !important;
  font-weight:bold;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 20px !important;
  line-height: 24px !important;
}
/*zongdu colur*/
yt-live-chat-text-message-renderer[privilegetype="1"] #author-name[type="member"],
yt-live-chat-text-message-renderer yt-live-chat-author-badge-renderer[type="member"] {
  color: #fc9b0b !important;
  font-weight:bold;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 20px !important;
  line-height: 24px !important;
}
/*tidu colur*/
yt-live-chat-text-message-renderer[privilegetype="2"] #author-name[type="member"],
yt-live-chat-text-message-renderer yt-live-chat-author-badge-renderer[type="member"] {
  color: #814faf !important;
  font-weight:bold;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 20px !important;
  line-height: 24px !important;
}
/*jianzhang colur*/
yt-live-chat-text-message-renderer[privilegetype="3"] #author-name[type="member"],
yt-live-chat-text-message-renderer yt-live-chat-author-badge-renderer[type="member"] {
  color: #097248 !important;
  font-weight:bold;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 20px !important;
  line-height: 24px !important;
}



/* Show colon */
yt-live-chat-text-message-renderer #author-name::after {
  content: ":";
  margin-left: 1px;
}

/* Hide badges */
yt-live-chat-text-message-renderer #chat-badges {

  vertical-align: text-top !important;
}

/* Messages */
yt-live-chat-text-message-renderer #message {
  font-weight:bold;
  color: #316d8f !important;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 18px !important;
  line-height: 24px !important;
}
yt-live-chat-text-message-renderer[privilegetype="1"] #message {
  font-weight:bold;
  color: #e0911b !important;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 18px !important;
  line-height: 24px !important;
}
yt-live-chat-text-message-renderer[privilegetype="2"] #message {
  font-weight:bold;
  color: #814faf !important;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 18px !important;
  line-height: 24px !important;
}
yt-live-chat-text-message-renderer[privilegetype="3"] #message {
  font-weight:bold;
  color: #2c6e54  !important;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 18px !important;
  line-height: 24px !important;
}

/* Timestamps */
yt-live-chat-text-message-renderer #timestamp {
  display: none !important;
  color: #316d8f !important;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 16px !important;
  line-height: 16px !important;
}

/* Background colors */
body {
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
}

yt-live-chat-text-message-renderer,
yt-live-chat-text-message-renderer[is-highlighted] {
  background-color: rgba(0, 0, 0, 0) !important;
}

yt-live-chat-text-message-renderer[author-type="owner"],
yt-live-chat-text-message-renderer[author-type="owner"][is-highlighted] {
  background-color: rgba(180, 22, 22, 0);
}


yt-live-chat-text-message-renderer[author-type="owner"] > div #image-and-messagee{
  border: 15px solid transparent;
  margin: 0px;
  padding: 2px 8px 2px 5px;
  border-image: url("https://pb.xuehusang.cn/images/bd16b8efa23bd49a951e95519c50fdaa.png") 100 fill;
  display: block !important;
}

yt-live-chat-text-message-renderer[author-type="moderator"] > div #image-and-message{
  border: 15px solid transparent;
  margin: 0px;
  padding: 2px 8px 2px 5px;
  border-image: url("https://pb.xuehusang.cn/images/ea5d137a0eee237064202ad823c029a1.png") 100 fill;
  display: block !important;
}

yt-live-chat-text-message-renderer[privilegetype="1"][author-type="member"] > div #image-and-message{
  border: 15px solid transparent;
  margin: 0px;
  padding: 2px 8px 2px 5px;
  border-image: url("https://pb.xuehusang.cn/images/94a4d8b37fbfb92610db18cb1de81692.png") 100 fill;
  display: block !important;
}

yt-live-chat-text-message-renderer[privilegetype="2"][author-type="member"] > div #image-and-message{
  border: 15px solid transparent;
  margin: 0px;
  padding: 2px 8px 2px 5px;
  border-image: url("https://pb.xuehusang.cn/images/bd16b8efa23bd49a951e95519c50fdaa.png") 100 fill;
  display: block !important;
}

yt-live-chat-text-message-renderer[privilegetype="3"][author-type="member"] > div #image-and-message{
  border: 15px solid transparent;
  margin: 0px;
  padding: 2px 8px 2px 5px;
  border-image: url("https://pb.xuehusang.cn/images/6b037dadbb3968c7fcac1d9e862cf9a1.png") 100 fill;
  display: block !important;
}

yt-live-chat-text-message-renderer > div #image-and-message{
  border: 15px solid transparent;
  margin: 0px;
  padding: 2px 8px 2px 5px;
  border-image: url("https://pb.xuehusang.cn/images/ea5d137a0eee237064202ad823c029a1.png") 100 fill;
  display: block !important;
}
/* SuperChat/Fan Funding Messages */
yt-live-chat-paid-message-renderer {
  margin: 4px 0 !important;
  max-width: 430px;
}
yt-live-chat-paid-message-renderer #header {
  border-radius: 20px 20px 0px 0px !important;
}
yt-live-chat-paid-message-renderer #content {
  border-radius: 0px 0px 20px 20px !important;
}
yt-live-chat-paid-message-renderer #author-name,
yt-live-chat-paid-message-renderer #author-name *,
yt-live-chat-membership-item-renderer #header-content-inner-column,
yt-live-chat-membership-item-renderer #header-content-inner-column * {
  color: #ffffff !important;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 20px !important;
  line-height: 22px !important;
  font-weight: bold;
}
yt-live-chat-paid-message-renderer #purchase-amount,
yt-live-chat-paid-message-renderer #purchase-amount * {
  color: #ffffff !important;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 18px !important;
  line-height: 18px !important;
}
yt-live-chat-membership-item-renderer #header-subtext,
yt-live-chat-membership-item-renderer #header-subtext * {
  color: #ffffff !important;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 18px !important;
  line-height: 20px !important;
}

yt-live-chat-paid-message-renderer #content,
yt-live-chat-paid-message-renderer #content * {
  color: #ffffff !important;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
  font-size: 20px !important;
  line-height: 30px !important;
  font-weight: bold;
}

yt-live-chat-membership-item-renderer #card{
  background-color: transparent !important;
}
yt-live-chat-membership-item-renderer #card > div::after{
  content:"gugugu";
  color: #FFFFFF !important;
  border: 2px #ffffff solid;
  border-radius: 10px;
  padding: 2px 11px 3px 12px;
}
yt-live-chat-membership-item-renderer #header {
  background-color: #2c6e54 !important;
  max-width: 400px;
  border: 0px #000000 solid;
  border-radius: 20px;
}

/* SuperChat Ticker */
yt-live-chat-ticker-paid-message-item-renderer,
yt-live-chat-ticker-paid-message-item-renderer *,
yt-live-chat-ticker-sponsor-item-renderer,
yt-live-chat-ticker-sponsor-item-renderer * {
  color: #ffffff !important;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE \8F6F \96C5 \9ED1 ", SimHei, Arial, sans-serif;
}

@keyframes message-anim {
  0% { opacity: 0; transform: translateX(-16px); }
  100% { opacity: 1; transform: none; }
}

@keyframes paid-anim {
  0% {
    opacity: 0;
    transform: rotateX(-360deg) scale(0.5,0.5);
  }
  100% {
  }
}

yt-live-chat-text-message-renderer{
  animation: message-anim 50ms;
  animation-fill-mode: both;
}

yt-live-chat-membership-item-renderer,
yt-live-chat-paid-message-renderer {
  animation: paid-anim 1100ms;
  animation-fill-mode: both;
}

/* Medal */
yt-live-chat-author-medal-renderer {
  display: none;

}
yt-live-chat-author-medal-renderer[is-fan-group] {
  display: flex;
}
#medal-name.yt-live-chat-author-medal-renderer {

  font-size: 18px !important;
  line-height: 18px !important;
}

#medal-level.yt-live-chat-author-medal-renderer {

  font-size: 18px !important;
  line-height: 18px !important;
}

yt-live-chat-ticker-renderer {
  display: none !important;
}

yt-live-chat-text-message-renderer #emoticon img {
  width: 80px !important;
}
yt-live-chat-text-message-renderer #emoticon {
  border: 0px solid transparent;
  margin: 7px;
  padding: 2px 0px 2px 5px;
  background-color: rgba(255,255,255,0.65);
  border-radius: 6px;
}
.main {
    height: 100% !important;
  background-color: #2b2f3a !important;
  }

#iframe {
    background-color: #2b2f3a !important;
}

</style>