<template>
  <div>
    <h1>弹幕样式</h1>
    <p>2.0</p>
    <p>@import url("https://xuehusang.cn/css/xuehu2.0.css");</p>
    <p>老款</p>
    <p>@import url("https://xuehusang.cn/css/xuehu-old.css");</p>
    <p>新款</p>
    <p>@import url("https://xuehusang.cn/css/xuehu.css");</p>
    <h2>粉丝数实时显示（添加浏览器源）(刷新频率6s)</h2>
    <p>https://xuehusang.cn/fans_obs</p>
    <h2>全屏上舰特效</h2>
    <p>OBS加入浏览器源，宽1920，高1080，铺满全屏，图层置于顶层，建议控制帧率为25，勾选不可见时关闭源，可见时自动刷新</p>
    <p>https://danmu.xuehusang.cn/fullScreenCele/24393</p>
    <p>测试链接</p>
    <p>https://danmu.xuehusang.cn/fullScreenCele/test</p>
  </div>
</template>

<script>
export default {
  name: 'Help'
}
</script>

<style scoped>
  p,h1,h2 {
    color: black;
  }
</style>
